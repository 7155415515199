import { ChainId, Token } from '@pancakeswap/sdk'

export const USDT_ROBIN_TESTNET = new Token(
  ChainId.ROBIN_TESTNET,
  '0x04Fe1A4C3b5C5fC58746ba79af02835Ca5D9e567',
  18,
  'USDT',
  'Mock USDT',
)

export const WRPG_TESTNET = new Token(
  ChainId.ROBIN_TESTNET,
  '0x71d9CFd1b7AdB1E8eb4c193CE6FFbe19B4aeE0dB',
  18,
  'RPG',
  'RPG',
)

export const BTC_ROBIN = new Token(
  ChainId.ROBIN_TESTNET,
  '0x582E579d6908cF670965e6Dce78CeF28391Aa5Bf',
  18,
  'BTC',
  'Mock BTC',
)

export const CAKE_ROBIN = new Token(
  ChainId.ROBIN_TESTNET,
  '0xb73015fdb3Dc75a501f8cEE9D66467F904D9dc76',
  18,
  'CAKE',
  'Mock CAKE',
)

export const USDT_ROBIN = new Token(
  ChainId.ROBIN,
  '0x8E8816a1747fDDC5F8B45d2e140a425D3788f659',
  18,
  'USDT',
  'Tether USD',
)

export const PVT_MAINNET = new Token(
  ChainId.ROBIN,
  '0x502DBf01Ae3214004B001635cD90b3228535675C',
  18,
  'PVT',
  'Ponyta Votes Token',
)

export const WRPG_MAINNET = new Token(ChainId.ROBIN, '0x71d9CFd1b7AdB1E8eb4c193CE6FFbe19B4aeE0dB', 18, 'RPG', 'RPG')

export const BUSD = {
  [ChainId.ROBIN_TESTNET]: BTC_ROBIN,
}

export const CAKE = {
  [ChainId.ROBIN_TESTNET]: CAKE_ROBIN,
}

export const USDT = {
  [ChainId.ROBIN]: USDT_ROBIN,
  [ChainId.ROBIN_TESTNET]: USDT_ROBIN_TESTNET,
}

export const WRPG = {
  [ChainId.ROBIN]: WRPG_MAINNET,
  [ChainId.ROBIN_TESTNET]: WRPG_TESTNET,
}

export const PVT = {
  [ChainId.ROBIN]: PVT_MAINNET,
}
