// import { ChainId } from '@pancakeswap/sdk'
import { formatEther } from '@ethersproject/units'
import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
// import { chains } from 'utils/wagmi'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import { getFarmConfig } from 'config/constants/farms'
import type { AppState } from 'state'
import { getFarmsPriceHelperLpFiles } from 'config/constants/priceHelperLps/index'
import { multicallv2 } from 'utils/multicall'
import { chains } from 'utils/wagmi'
import { createFarmV1Fetcher } from '@pancakeswap/farms'
// import fetchFarms from './fetchFarms'
// import { SerializedFarmConfig } from 'config/constants/types'
import { fetchPoolsTokenPrices } from 'state/pools/fetchPools'
import getFarmsPrices from './getFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { resetUserState } from '../global/actions'
import { SerializedFarmsState, SerializedFarm } from '../types'
// import { fetchMasterChefFarmPoolLength } from './fetchMasterChefData'

const farmFetcher = createFarmV1Fetcher(multicallv2)
// console.log('farmFetcher-----------------', farmFetcher)

const initialState: SerializedFarmsState = {
  data: [],
  loadArchivedFarmsData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchInitialFarmsData = createAsyncThunk<SerializedFarm[], { chainId: number }>(
  'farmsV1/fetchInitialFarmsData',
  async ({ chainId }) => {
    const farmDataList = await getFarmConfig(chainId)
    console.log('farmv1------------------fetchInitialFarmsData111')
    return farmDataList.map((farm) => ({
      ...farm,
      userData: {
        allowance: '0',
        tokenBalance: '0',
        stakedBalance: '0',
        earnings: '0',
      },
    }))
  },
)

export const fetchFarmsPublicDataAsync = createAsyncThunk<
  [SerializedFarm[], number, number],
  { pids: number[]; chainId?: number },
  {
    state: AppState
  }
>(
  'farmsV1/fetchFarmsPublicDataAsync',
  async ({ pids, chainId }) => {
    const chain = chains.find((c) => c.id === chainId)
    if (!chain || !farmFetcher.isChainSupported(chain.id)) throw new Error('chain not supported')
    try {
      const farmsConfig = localStorage.getItem('farmsList') ? JSON.parse(localStorage.getItem('farmsList')) : []
      const farmConfig = localStorage.getItem('farmData') ? JSON.parse(localStorage.getItem('farmData')) : {}

      const { poolLength, totalAllocPoint, cakePerBlock } = await farmFetcher.fetchMasterChefV1Data(
        chain.testnet,
        farmConfig?.masterChefV1,
      )

      const [farmsTokenPrices] = await Promise.all([fetchPoolsTokenPrices()])

      const farmsCanFetch = farmsConfig.filter((farm) => pids?.includes(farm.pid) && poolLength.gt(farm.pid % 100))
      console.log('farmv1------------------fetchInitialFarmsData222')

      const regularCakePerBlock = formatEther(cakePerBlock)
      const priceHelperLpsConfig = getFarmsPriceHelperLpFiles(chainId)

      const farms = await farmFetcher.fetchFarmsV1(
        {
          farms: farmsCanFetch.concat(priceHelperLpsConfig),
          isTestnet: chain.testnet,
          chainId,
          totalAllocPoint,
        },
        farmConfig?.masterChefV1,
      )

      const liveData = farms.map((farm) => {
        const quoteIconObj = farmsTokenPrices?.filter(
          (v) => v.address.toLowerCase() === farm.quoteToken.address.toLowerCase(),
        )[0]
        const tokenIconObj = farmsTokenPrices?.filter(
          (v) => v.address.toLowerCase() === farm.token.address.toLowerCase(),
        )[0]

        return {
          ...farm,
          quoteIcon: quoteIconObj.icon,
          tokenIcon: tokenIconObj.icon,
        }
      })

      const farmsWithPrices = liveData.length > 0 ? getFarmsPrices(liveData, chainId, farmsConfig) : []

      // console.log('farmsWithPrices', farmsWithPrices, farmsConfig)

      return [farmsWithPrices, poolLength.toNumber(), +regularCakePerBlock]
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  {
    condition: (arg, { getState }) => {
      const { farmsV1 } = getState()
      if (farmsV1.loadingKeys[stringify({ type: fetchFarmsPublicDataAsync.typePrefix, arg })]) {
        console.debug('farmsV1 action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

interface FarmUserDataResponse {
  pid: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  { account: string; pids: number[] },
  {
    state: AppState
  }
>(
  'farmsV1/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    console.log('farmv1------------------fetchInitialFarmsData333')
    const farmsConfig = localStorage.getItem('farmsList') ? JSON.parse(localStorage.getItem('farmsList')) : []

    // const poolLength = await fetchMasterChefFarmPoolLength(farmsConfig)
    // console.log('fetchFarmUserDataAsync', poolLength.toString())

    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
    const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
    const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)

    return userFarmAllowances.map((_, index) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: userFarmAllowances[index],
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings: userFarmEarnings[index],
      }
    })
  },
  {
    condition: (arg, { getState }) => {
      const { farmsV1 } = getState()
      if (farmsV1.loadingKeys[stringify({ type: fetchFarmUserDataAsync.typePrefix, arg })]) {
        console.debug('farmsV1 user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const farmsSlice = createSlice({
  name: 'FarmsV1',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map((farm) => {
        return {
          ...farm,
          userData: {
            allowance: '0',
            tokenBalance: '0',
            stakedBalance: '0',
            earnings: '0',
          },
        }
      })
      state.userDataLoaded = false
    })
    // Init farm data
    builder.addCase(fetchInitialFarmsData.fulfilled, (state, action) => {
      const farmData = action.payload
      state.data = farmData
    })

    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      const [farmPayload, poolLength, regularCakePerBlock] = action.payload
      if (state.data.length > 0) {
        // console.log('Update farms with live data', action.payload)
        state.data = state.data.map((farm) => {
          const liveFarmData = farmPayload.find((farmData) => farmData.pid === farm.pid)
          return { ...farm, ...liveFarmData }
        })
      } else {
        state.data = farmPayload
      }
      state.poolLength = poolLength
      state.regularCakePerBlock = regularCakePerBlock
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      // console.log('Update farms with user data', action.payload)
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => farm.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })

    builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.pending, fetchFarmsPublicDataAsync.pending), (state, action) => {
      state.loadingKeys[serializeLoadingKey(action, 'pending')] = true
    })
    builder.addMatcher(
      isAnyOf(fetchFarmUserDataAsync.fulfilled, fetchFarmsPublicDataAsync.fulfilled),
      (state, action) => {
        state.loadingKeys[serializeLoadingKey(action, 'fulfilled')] = false
      },
    )
    builder.addMatcher(
      isAnyOf(fetchFarmsPublicDataAsync.rejected, fetchFarmUserDataAsync.rejected),
      (state, action) => {
        state.loadingKeys[serializeLoadingKey(action, 'rejected')] = false
      },
    )
  },
})

export default farmsSlice.reducer
