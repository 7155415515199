import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR } from 'config'
// import lpAprs56 from 'config/constants/lpAprs/56.json'

const getLpApr = (chainId: number) => {
  switch (chainId) {
    // case 56:
    //   return lpAprs56
    default:
      return {}
  }
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  // 矿池总权重 totalAllocPoint()
  // 矿池权重 PoolInfo.allocPoint()
  // 出块奖励 cakePerBlock()

  // 每一年的出块数量   365 * 24 * 3600 = 31536000 块 = BLOCKS_PER_YEAR
  // 池子产出          (池子权重 / 总权重) * 出块奖励
  // 池子产出 = (100 / 200) * tokenPerBlock

  // APR = (池子产出 * BLOCKS_PER_YEAR * rewardTokenPrice) / totalStaked * stakingTokenPrice
  // const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  // const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  // const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)

  // APR = 一年的产出 / 总投入 =  ( 池子产出 * 出块数量 * 奖励币的价格) / 质押总数量 * 质押币的价格
  const apr = new BigNumber(
    ((tokenPerBlock * BLOCKS_PER_YEAR * rewardTokenPrice) / (totalStaked * stakingTokenPrice)) * 100,
  )

  // console.log('tokenPerBlock', tokenPerBlock, totalStaked, rewardTokenPrice, stakingTokenPrice)

  // console.log('getPoolApr===', (((100 / 200) * tokenPerBlock * BLOCKS_PER_YEAR * rewardTokenPrice) / totalStaked) * 1)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock?: number,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  const yearlyCakeRewardAllocation = poolWeight
    ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
    : new BigNumber(NaN)

  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)

  // console.log(
  //   'yearlyCakeRewardAllocation===',
  //   yearlyCakeRewardAllocation.toString(),
  //   cakePriceUsd.toString(),
  //   poolLiquidityUsd.toString(),
  // )
  // console.log('poolWeight===', cakeRewardsApr)

  // console.log(
  //   'new BigNumber(farm.poolWeight)=======',
  //   poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock).toString(),
  //   poolWeight.toString(),
  // )

  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
  }
  const lpRewardsApr = getLpApr(chainId)[farmAddress?.toLowerCase()] ?? 0
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export default null
