import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import { MultiCallV2 } from '@pancakeswap/multicall'
import { ChainId } from '@pancakeswap/sdk'
import { BIG_TEN, FIXED_TWO, FIXED_ZERO } from './const'
import { getFarmsPrices } from './farmPrices'
import { fetchPublicFarmsDataV1 } from './fetchPublicFarmData'
import { SerializedFarmConfig } from './types'

export const getTokenAmount = (balance: FixedNumber, decimals: number) => {
  const tokenDividerFixed = FixedNumber.from(BIG_TEN.pow(decimals))
  return balance.divUnsafe(tokenDividerFixed)
}

export type fetchV1FarmsParams = {
  farms
  multicall: MultiCallV2
  isTestnet: boolean
  masterChefAddresses?: Record<number, string>
  masterChefAddressesV1?: Record<number, string>
  chainId: number
  totalAllocPoint: BigNumber
}

export async function farmV1FetchFarms({
  farms,
  multicall,
  isTestnet,
  masterChefAddressesV1,
  chainId,
  totalAllocPoint,
}: fetchV1FarmsParams) {
  const lpData = (await fetchPublicFarmsDataV1(farms, chainId, multicall)).map(formatFarmResponse)
  const poolInfos = await fetchMasterChefData(farms, isTestnet, multicall, masterChefAddressesV1)
  // console.log('lpData', lpData, poolInfos)

  const farmsData = farms.map((farm, index) => {
    try {
      return {
        pid: farm.pid,
        ...farm,
        ...getFarmsDynamicData({
          ...lpData[index],
          allocPoint: poolInfos[index]?.allocPoint,
          token0Decimals: farm.token.decimals,
          token1Decimals: farm.quoteToken.decimals,
          totalAllocPoint,
        }),
      }
    } catch (error) {
      console.log('error=======================================')
      console.error(error, farm, index, {
        allocPoint: poolInfos[index]?.allocPoint,
        token0Decimals: farm.token.decimals,
        token1Decimals: farm.quoteToken.decimals,
        totalAllocPoint,
      })
      throw error
    }
  })

  const farmsDataWithPrices = getFarmsPrices(farmsData, chainId)
  // console.log("farmsData------------------------", farmsDataWithPrices)

  return farmsDataWithPrices
}

const masterChefV1Abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'poolInfo',
    outputs: [
      {
        internalType: 'contract IBEP20',
        name: 'lpToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'allocPoint',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'lastRewardBlock',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'accCakePerShare',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'poolLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalAllocPoint',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cakePerBlock',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const masterChefFarmCalls = (farm: SerializedFarmConfig, isTestnet: boolean, masterChefAddressesV1: string) => {
  const { pid } = farm
  // const masterChefAddress = masterChefAddressesV1[isTestnet ? ChainId.ROBIN_TESTNET : ChainId.ROBIN]

  return pid || pid === 0
    ? {
        address: masterChefAddressesV1,
        name: 'poolInfo',
        params: [pid % 100],
      }
    : null
}

export const fetchMasterChefData = async (
  farms: SerializedFarmConfig[],
  isTestnet: boolean,
  multicall,
  masterChefAddressesV1,
): Promise<any[]> => {
  try {
    const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm, isTestnet, masterChefAddressesV1))
    const masterChefAggregatedCalls = masterChefCalls.filter((masterChefCall) => masterChefCall !== null)

    const masterChefMultiCallResult = await multicall({
      abi: masterChefV1Abi,
      calls: masterChefAggregatedCalls,
      chainId: isTestnet ? ChainId.ROBIN_TESTNET : ChainId.ROBIN,
    })

    let masterChefChunkedResultCounter = 0
    return masterChefCalls.map((masterChefCall) => {
      if (masterChefCall === null) {
        return null
      }
      const data = masterChefMultiCallResult[masterChefChunkedResultCounter]
      masterChefChunkedResultCounter++
      return data
    })
  } catch (error) {
    console.error('MasterChefV1 Farm info data error', error)
    throw error
  }
}

export const fetchMasterChefV1Data = async ({
  isTestnet,
  multicall,
  masterChefAddressesV1,
}: {
  isTestnet: boolean
  multicall: MultiCallV2
  masterChefAddressesV1
}) => {
  try {
    console.log('fetchMasterChefV1Data', masterChefAddressesV1)
    // const masterChefV1Address = masterChefAddressesV1[isTestnet ? ChainId.ROBIN_TESTNET : ChainId.ROBIN]

    const [[poolLength], [totalAllocPoint], [cakePerBlock]] = await multicall<[[BigNumber], [BigNumber], [BigNumber]]>({
      abi: masterChefV1Abi,
      calls: [
        {
          address: masterChefAddressesV1,
          name: 'poolLength',
        },
        {
          address: masterChefAddressesV1,
          name: 'totalAllocPoint',
        },
        {
          address: masterChefAddressesV1,
          name: 'cakePerBlock',
        },
      ],
      chainId: isTestnet ? ChainId.ROBIN_TESTNET : ChainId.ROBIN,
    })

    return {
      poolLength,
      totalAllocPoint,
      cakePerBlock,
    }
  } catch (error) {
    console.error('Get MasterChefV1 data error', error)
    throw error
  }
}

type balanceResponse = [BigNumber]
type decimalsResponse = [number]

export type LPData = [
  balanceResponse,
  balanceResponse,
  balanceResponse,
  balanceResponse,
  decimalsResponse,
  decimalsResponse,
]

type FormatFarmResponse = {
  tokenBalanceLP: FixedNumber
  quoteTokenBalanceLP: FixedNumber
  lpTokenBalanceMC: FixedNumber
  lpTotalSupply: FixedNumber
}

const formatFarmResponse = (farmData: LPData): FormatFarmResponse => {
  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply] = farmData
  return {
    tokenBalanceLP: FixedNumber.from(tokenBalanceLP[0]),
    quoteTokenBalanceLP: FixedNumber.from(quoteTokenBalanceLP[0]),
    lpTokenBalanceMC: FixedNumber.from(lpTokenBalanceMC[0]),
    lpTotalSupply: FixedNumber.from(lpTotalSupply[0]),
  }
}

export const getFarmsDynamicData = ({
  lpTokenBalanceMC,
  lpTotalSupply,
  quoteTokenBalanceLP,
  tokenBalanceLP,
  totalAllocPoint,
  token0Decimals,
  token1Decimals,
  allocPoint,
}: // isRegular = true,
FormatFarmResponse & {
  allocPoint?: BigNumber
  // isRegular?: boolean
  totalAllocPoint: BigNumber
  token0Decimals: number
  token1Decimals: number
}) => {
  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = getTokenAmount(tokenBalanceLP, token0Decimals)
  const quoteTokenAmountTotal = getTokenAmount(quoteTokenBalanceLP, token1Decimals)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio =
    !lpTotalSupply.isZero() && !lpTokenBalanceMC.isZero() ? lpTokenBalanceMC.divUnsafe(lpTotalSupply) : FIXED_ZERO

  // // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMcFixed = quoteTokenAmountTotal.mulUnsafe(lpTokenRatio)

  // // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMcFixed.mulUnsafe(FIXED_TWO)

  const _allocPoint = allocPoint ? FixedNumber.from(allocPoint) : FIXED_ZERO
  const totalAlloc = totalAllocPoint

  const poolWeight =
    !totalAlloc.isZero() && !_allocPoint.isZero() ? _allocPoint.divUnsafe(FixedNumber.from(totalAlloc)) : FIXED_ZERO

  // console.log(
  //   '!quoteTokenAmountTotal.isZero()',
  //   !quoteTokenAmountTotal.isZero(),
  //   quoteTokenAmountTotal.divUnsafe(tokenAmountTotal).toString(),
  // )

  return {
    tokenAmountTotal: tokenAmountTotal.toString(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
    lpTotalSupply: lpTotalSupply.toString(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
    tokenPriceVsQuote: !quoteTokenAmountTotal.isZero() && quoteTokenAmountTotal.divUnsafe(tokenAmountTotal).toString(),
    poolWeight: poolWeight.toString(),
    multiplier: !_allocPoint.isZero() ? `${+_allocPoint.divUnsafe(FixedNumber.from(100)).toString()}X` : `0X`,
  }
}
