import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  nftStake: {
    9527: '0x0B710A5AB21309C6313e7eA5977ee59caF1c117E',
  },
  voteGovernor: {
    9527: '0x5e4f49c63438745ee48101F4dD512ED565B18682',
  },
  votesToken: {
    9527: '0x32eaCa3B2D5872b945c4b42679D85d8F11B08992',
  },
  createAuction: {
    9527: '0xA4899D35897033b927acFCf422bc745916139776', // AuctionCreation 合约地址
  },
  limitOrderV1: {
    9527: '0x4b9885F959cC0D482867Fa338c570D17032Dd5e7',
    2025: '0x920C70d4cf2d339dB9fBCde82eda2463E684538D',
  }, // 合约名Ponyta Limit Order Protocol
  farmBox: {
    9527: '0xd4e8015863C7BDF41EFc9afC640C7E28b97dfB4e',
  },
  // cakeTokenV1: {
  //   9527: '0xA17167BdD6C102654d47FD9a0618F2f0b2A095fc',
  // },
  // rpgTokenV1: {
  //   9527: '0xc1Fa81dAbb194994d254fa485c6617D2f6517991',
  // },
  // maticTokenV1: {
  //   9527: '0xE05da875964fCcAcb0F7B7B3A12932c00851F43B',
  // },
  // usdtTokenV1: {
  //   9527: '0x1D9AB520C241e6AD9D687cD471c9D225C4324305',
  // },
  // masterChefV1: {
  //   9527: '0x64579C2ac46B039E08aCA3197F8e2CF3446E31c3',
  //   2025: '0x81Ca5BdA8f591a4376E21334aB246f35ECEe8687',
  // },
  multiCall: multicallAddresses,
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  masterChef: {
    97: '',
    56: '',
  },
  pancakeProfile: {
    56: '',
    97: '',
  },
  pancakeBunnies: {
    56: '',
    97: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  cakeVault: {
    56: '',
    97: '',
  },
  cakeFlexibleSideVault: {
    56: '',
    97: '',
  },
  chainlinkOracleBNB: {
    56: '',
    97: '',
  },
  chainlinkOracleCAKE: {
    56: '',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '',
    97: '',
  },
  bunnySpecialXmas: {
    56: '',
    97: '',
  },
  AnniversaryAchievement: {
    56: '',
    97: '',
  },
  // TODO: multi
  zap: {
    56: '',
    97: '',
  },
  iCake: {
    56: '',
    97: '',
  },
  bCakeFarmBooster: {
    56: '',
    97: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '',
    97: '',
  },
}
