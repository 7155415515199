import { ChainId } from '@pancakeswap/sdk'
import chunk from 'lodash/chunk'
import erc20 from 'config/abi/erc20.json'
// import { getMasterChefV1Address } from 'utils/addressHelpers'
import { SerializedFarmPublicData, SerializedFarmConfig } from './types'

const abi = [
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: 'balance',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
]

const fetchFarmCalls = (farm: SerializedFarmPublicData, chainId: number) => {
  console.log('fetchFarmCalls111111111111111111111111111111111111111')
  const { lpAddress, token, quoteToken } = farm
  return [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
  ]
}

export const fetchPublicFarmsData = async (
  farms: SerializedFarmConfig[],
  chainId = ChainId.ROBIN_TESTNET,
  multicall,
): Promise<any[]> => {
  try {
    const farmCalls = farms.flatMap((farm) => fetchFarmCalls(farm, chainId))
    const chunkSize = farmCalls.length / farms.length
    // console.log('fetchPublicFarmsData0', farmCalls, chunkSize)
    const farmMultiCallResult = await multicall({ abi, calls: farmCalls, chainId })
    // console.log('fetchPublicFarmsData0-0', farmMultiCallResult)
    return chunk(farmMultiCallResult, chunkSize)
  } catch (error) {
    console.error('MasterChef Public Data error ', error)
    throw error
  }
}

const fetchFarmCallsV1 = (farm: SerializedFarmPublicData, chainId: number) => {
  const { masterChefV1, lpAddress, token, quoteToken } = farm
  return [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [masterChefV1],
    },
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
  ]
}

export const fetchPublicFarmsDataV1 = async (
  farms: SerializedFarmConfig[],
  chainId = ChainId.ROBIN_TESTNET,
  multicall,
): Promise<any[]> => {
  try {
    const farmCalls = farms.flatMap((farm) => fetchFarmCallsV1(farm, chainId))
    const chunkSize = farmCalls.length / farms.length
    const farmMultiCallResult = await multicall({ abi: erc20, calls: farmCalls, chainId })
    return chunk(farmMultiCallResult, chunkSize)
  } catch (error) {
    console.error('MasterChefV1 Public Data error ', error)
    throw error
  }
}
