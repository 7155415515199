import { ChainId } from '@pancakeswap/sdk'
import { BIG_TEN } from 'utils/bigNumber'

export const BSC_BLOCK_TIME = 1
// [ChainId.ROBIN]: 1.6,
// [ChainId.ROBIN_TESTNET]: 1,

export const BASE_BSC_SCAN_URLS = {
  // [ChainId.BSC]: 'https://bscscan.com',
  // [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}

export const FORMAT_START_TIME = '2023-07-30 14:00:00' // 活动开始北京时间
export const FORMAT_END_TIME = '2024-06-13 14:00:00' // 活动结束北京时间
export const START_TIME = '2023-05-30 06:00 UTC' // 活动开始时间
export const END_TIME = '-' // 活动结束时间

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0.001017
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 31536000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://www.ponytaswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.ROBIN_TESTNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_ORDER_TO_FETCH = 10000000000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
