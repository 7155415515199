import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["ROBIN_TESTNET"] = 9527] = "ROBIN_TESTNET";
    ChainId[ChainId["ROBIN"] = 2025] = "ROBIN";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.ROBIN, "0x1589DD24f11e1e49566fE99744E7487CbcAb2d43"), _define_property(_obj, ChainId.ROBIN_TESTNET, "0x06eD60Af119Efe7659733c6bf8A558eaa274F739"), _obj);
export var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.ROBIN, "0xfe0ffe17ed7e88458bb95b83d63aa2fb01b3a155db61ff043dd647980cb14a5c"), _define_property(_obj1, ChainId.ROBIN_TESTNET, "0xfe0ffe17ed7e88458bb95b83d63aa2fb01b3a155db61ff043dd647980cb14a5c"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.ROBIN, new Token(ChainId.ROBIN, "0x8bB3887Ab4Fec66EB9aAdd7836B6944215008355", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.ROBIN_TESTNET, new Token(ChainId.ROBIN_TESTNET, "0x0280612Df50A35133A1fcCdA0450b8d30c3459bD", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj3);
export var WBNB = _define_property({}, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org"));
var _obj4;
export var WNATIVE = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj4, ChainId.ROBIN, WETH9[ChainId.ROBIN_TESTNET]), _define_property(_obj4, ChainId.ROBIN_TESTNET, WETH9[ChainId.ROBIN_TESTNET]), _obj4);
var _obj5;
export var NATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj5, ChainId.ROBIN, {
    name: "Rangers Ether",
    symbol: "RPG",
    decimals: 18
}), _define_property(_obj5, ChainId.ROBIN_TESTNET, {
    name: "Rangers Ether",
    symbol: "RPG",
    decimals: 18
}), _obj5);
