import { BigNumber } from '@ethersproject/bignumber'
// import { robTestnetTokens } from '@pancakeswap/tokens'
// import addresses from 'config/constants/contracts'
import qs from 'qs'
import { SerializedPoolConfig, PoolCategory } from './types'
import { GET_POOL_LIST } from './endpoints'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

let logged = false

export const getPoolConfig = async () => {
  const params = qs.stringify({
    page: 1,
    page_size: 100,
    isView: 'true',
  })
  try {
    const result = await (await fetch(`${GET_POOL_LIST}?${params}`)).json()
    const poolsList: SerializedPoolConfig[] = []
    if (result?.status === 200) {
      // console.log('代币质押数据', result?.data?.content)
      const data = result?.data.content ? result?.data.content : []
      if (data.length) {
        data.forEach((item: any) => {
          poolsList.push({
            sousId: item.poolIndex,
            stakingToken: item.stakingToken,
            earningToken: item.CcStakeToken.earningToken,
            contractAddress: item.CcStakeToken.address,
            poolCategory: PoolCategory.CORE,
            tokenPerBlock: item.tokenPerBlock,
            totalPerBlock: item.totalPerBlock,
          })
        })
        localStorage.setItem('poolsList', JSON.stringify(poolsList))
        return poolsList
      }
      localStorage.removeItem('poolsList')
      return []
    }
    return []
  } catch (error) {
    if (!logged) {
      console.error('Cannot get pool config', error)
      logged = true
    }
    return []
  }
}

export const livePools: SerializedPoolConfig[] = [
  // {
  //   sousId: 1000,
  //   stakingToken: robTestnetTokens.usdt,
  //   earningToken: robTestnetTokens.cake,
  //   contractAddress: addresses.cakeTokenV1,
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.5',
  //   totalPerBlock: '1',
  //   // isFinished: false,
  // },
  // {
  //   sousId: 1001,
  //   stakingToken: robTestnetTokens.rpg,
  //   earningToken: robTestnetTokens.cake,
  //   contractAddress: addresses.cakeTokenV1,
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.5',
  //   totalPerBlock: '1',
  // },
  // {
  //   sousId: 2000,
  //   stakingToken: robTestnetTokens.usdt,
  //   earningToken: robTestnetTokens.rpg,
  //   contractAddress: addresses.rpgTokenV1,
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.0005',
  //   totalPerBlock: '0.001',
  // },
  // {
  //   sousId: 3000,
  //   stakingToken: robTestnetTokens.rpg,
  //   earningToken: robTestnetTokens.matic,
  //   contractAddress: addresses.maticTokenV1,
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.5',
  //   totalPerBlock: '1',
  // },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools]
