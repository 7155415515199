import { ChainId, Token } from '@pancakeswap/sdk'
import { USDT_ROBIN, WRPG_MAINNET } from './common'

export const robMainnetTokens = {
  // weth: WETH9[ChainId.ROBIN],
  pvt: new Token(ChainId.ROBIN, '0x502DBf01Ae3214004B001635cD90b3228535675C', 18, 'PVT', 'Ponyta Votes Token', ''),
  mix: new Token(ChainId.ROBIN, '0x36426B7bF5709E5c2160411C6E8B1832e3404FE1', 18, 'MIX', 'MixMarvel Token', ''),
  amg: new Token(
    ChainId.ROBIN,
    '0xDAa6a6919C9543d8787490F5e9aD532c4d7ce9e8',
    18,
    'AMG',
    'DeHeroGame Amazing Token',
    '',
  ),
  wrpg: WRPG_MAINNET,
  usdt: USDT_ROBIN,
}
